<template>
  <Tarea />
</template>

<script>
import Tarea from './components/Tarea'

export default {
  name: 'App',
  components: {
    Tarea
  }
}
</script>

<style>

</style>
